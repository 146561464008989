<script>
import ConfirmationModal from '@/components/general/ConfirmationModal'
export default {
  components: { ConfirmationModal },
  name: 'InterviewInviteCard',
  props: {
    headIcon: {
      type: String
    },
    headTitle: {
      type: String
    },
    inviteStatus: {
      type: String
    },
    positionName: {
      type: String
    },
    positionId: {
      type: String
    },
    interviewId: {
      type: String
    },
    company: {
      type: Object
    },
    interviewDate: {
      type: Object
    },
    actionType: {
      type: String,
      default: 'acceptance'
    },
    confirmationModalTitle: {
      type: String
    },
    confirmationModalDescription: {
      type: String
    },
    allowRejectMeeting: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      showConfirmationModal: false
    }
  },
  methods: {
    confirmMeeting (responseMeeting) {
      let data
      if (responseMeeting !== 'decline') {
        data = {
          interviewId: this.interviewId,
          response: responseMeeting,
          notes: 'Reunião confirmada'
        }
      } else {
        data = {
          interviewId: this.interviewId,
          response: responseMeeting,
          notes: 'Não poderei participar da reunião'
        }
      }
      return this.$store.dispatch('attemptPutResponseMeetInvitation', data).then(() => this.$emit('refreshPendingInterview'))
    },
    confirmReject () {
      this.confirmMeeting('decline')
      this.$emit('confirmReject')
    },
    getUrlMeeting () {
      this.$router.push({ name: 'individual.interview', params: { id: this.positionId } })
    }
  }
}
</script>
<template>
  <div class="interview-invite-card--container">
    <div class="interview-invite--head">
      <div class="interview-invite--title-wrapper">
        <v-icon :color="getPrimaryColor">{{ headIcon }}</v-icon>
        <h3>{{ headTitle }}</h3>
      </div>
      <span class="interview-invite--status" v-if="inviteStatus">
        <v-icon class="ml-1 mr-1" size="6">mdi-circle</v-icon>
        {{ inviteStatus }}
      </span>
    </div>
    <div class="interview-invite--info">
      <div class="interview-invite--position">
        <p>{{ $t('global:position') }}</p>
        <h4>{{ positionName }}</h4>
      </div>
      <div class="interview-invite--company">
        <v-avatar size="22">
          <v-img :src="company.logo" height="22" width="22"></v-img>
        </v-avatar>
        <p>{{ company.name }}</p>
      </div>
      <div class="interview-invite--date">
        <div class="interview-invite--date-day">
          <v-icon :color="getPrimaryColor">mdi-calendar-blank</v-icon>
          <p>{{ $t('global:date') }}: <span>{{ interviewDate.day }}</span></p>
        </div>
        <div class="interview-invite--date-hour">
          <v-icon :color="getPrimaryColor">mdi-clock-outline</v-icon>
          <p>{{ $t('global:hour') }}: <span>{{ $i18n.locale === 'pt-BR' ? interviewDate.hour : brazilianToAmericanTime(interviewDate.hour) }}</span></p>
        </div>
      </div>
    </div>
    <div class="interview-invite--actions">
      <template v-if="actionType === 'acceptance'">
        <p>{{ $t('global:join') }}</p>
        <div class="interview-invite--buttons">
          <v-btn v-if="allowRejectMeeting" class="bold transform-unset mr-2 reject-interview-btn" :color="getPrimaryColor" outlined @click="showConfirmationModal = true">{{ $t('global:reject') }}</v-btn>
          <v-btn  :class="{'ml-2' : allowRejectMeeting}" class="bold transform-unset" @click="confirmMeeting('confirm')" :color="getPrimaryColor" dark><v-icon class="mr-2" size="14" color="#fff" >mdi-check</v-icon>{{ $t('global:accept') }}</v-btn>
        </div>
      </template>
      <template v-else>
        <v-btn class="bold transform-unset join-interview-btn" :color="getPrimaryColor" @click="getUrlMeeting" target="_blank" dark><v-icon size="14">mdi-arrow-top-right</v-icon>{{ $t('interview.invite:join.interview') }}</v-btn>
      </template>
    </div>
    <confirmation-modal
      :show="showConfirmationModal && !!confirmationModalTitle && !!confirmationModalDescription"
      :title="confirmationModalTitle"
      :description="confirmationModalDescription"
      :confirmText="$t('global:reject')"
      :btnConfirmText="false"
      @confirm="confirmReject()"
      :cancelText="$t('global:cancel')"
      @cancel="showConfirmationModal = false"
    />
  </div>
</template>
<style lang="scss">
.interview-invite-card--container {
  display: inline-block;
  width: 320px;
  max-width: 100%;
  margin-right: 16px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  .interview-invite--head {
    background: #FAFAFA;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    .interview-invite--title-wrapper {
      display: flex;
      align-items: center;
      .v-icon {
        margin-right: 8px;
      }
      h3 {
        font-family: $lato;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: #454545;
      }
    }
    .interview-invite--status {
      padding: 6px 12px 6px 8px;
      border-radius: 100px;
      font-family: $lato;
      font-weight: 700;
      font-size: 12px;
      line-height: 12px;
      background-color: rgba(0, 0, 0, .1);
      color: #D65F57;
      background: linear-gradient(0deg, rgba(214, 95, 87, 0.2), rgba(214, 95, 87, 0.2)), #FFFFFF;
      border: 1px solid #D65F57;
      text-transform: uppercase;
      .v-icon {
        margin-right: 4px;
        color: #D65F57;
      }
    }
  }
  .interview-invite--info {
    padding: 16px;
    background: #FFFFFF;
    .interview-invite--position {
      margin-bottom: 16px;
      p {
        font-family: 'Lato';
        font-size: 12px;
        line-height: 14px;
        color: #7E7D77;
        margin-bottom: 0;
      }
      h4 {
        font-family: 'Lato';
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
        height: 54px;
        overflow: hidden;
      }
    }
    .interview-invite--company {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      .v-avatar {
        margin-right: 8px;
        border-radius: 100px;
      }
      p {
        font-family: 'Lato';
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
        margin-bottom: 0;
      }
    }
    .interview-invite--date {
      padding: 8px;
      background: #FAFAFA;
      border: 1px solid rgba(0, 0, 0, 0.06);
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      > div {
        display: flex;
        align-items: center;
        &:nth-child(1) {
          margin-bottom: 8px;
        }
        .v-icon {
          margin-right: 4px;
        }
        p {
          font-family: 'Lato';
          font-style: normal;
          font-size: 14px;
          line-height: 17px;
          color: #7E7D77;
          margin-bottom: 0;
          span {
            color: #000000;
            font-weight: bold;
          }
        }
      }
    }
  }
  .interview-invite--actions {
    padding: 16px;
    background: #FAFAFA;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    p {
      margin-bottom: 8px;
      font-family: 'Lato';
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      color: #454545;
    }
    .interview-invite--buttons {
      display: flex;
      justify-content: space-between;
      .v-btn {
        flex-basis: calc(50% - 8px);
      }
    }
    .reject-interview-btn {
      background: #FFF;
    }
    .join-interview-btn {
      width: 100%;
    }
  }
}
</style>
