<script>
import TitleDescriptionSlot from '@/components/general/TitleDescriptionSlot'
import GenericSlider from '@/components/general/GenericSlider'
import TitleDescriptionCardAnchor from '@/components/general/TitleDescriptionCardAnchor'
import PendingMessage from '@/components/general/PendingMessage'
import PositionInvite from '@/components/general/PositionInvite'
import InterviewInviteCard from '@/components/general/InterviewInviteCard'
import Offer from '@/components/general/Offer'
export default {
  name: 'homepage-individual',
  components: {
    TitleDescriptionSlot,
    GenericSlider,
    TitleDescriptionCardAnchor,
    PendingMessage,
    PositionInvite,
    InterviewInviteCard,
    Offer
  },
  data () {
    return {
      messagesCounter: 0,
      data: [],
      username: '',
      userApplicationInvites: [],
      userUnreadMessages: [],
      userOffer: [],
      acceptedRemoteInterviews: [],
      positionSuggestions: [],
      courseSuggestions: [],
      getApplications: [],
      getMatches: [],
      handleContentInProgress: [],
      handleUnreadMessages: [],
      pendingRemoteInterviews: [],
      discoveryList: [],
      isPositionsLoading: true,
      isCoursesLoading: true,
      isDiscoveryLoading: true
    }
  },
  created () {
    this.$store.dispatch('attemptGetPositionSuggestionsHome', { user: this.getUserInfo, aggregator: this.getCompanyDomainAndWorkspace() }).then(dataPositionsSugestions => {
      this.positionSuggestions = dataPositionsSugestions.map(item => {
        if (!item.isFavorite) item.isFavorite = false
        return item
      })
      this.isPositionsLoading = false
    })

    this.$store.dispatch('attemptGetCourseSuggestionsHome', { aggregator: '' }).then(dataCourseSugestions => {
      this.courseSuggestions = dataCourseSugestions
      this.isCoursesLoading = false
    })

    this.$store.dispatch('attemptGetDiscoveryList', { sortBy: 'recent', filter: { categories: ['content'] } }).then(dataDiscovery => {
      if (dataDiscovery && dataDiscovery.data) this.discoveryList = dataDiscovery.data
      this.isDiscoveryLoading = false
    })

    Promise.all([
      this.$store.dispatch('attemptGetUserInfo'),
      this.$store.dispatch('attemptGetUserMessages'),
      this.$store.dispatch('attemptGetOfferLetter'),
      this.$store.dispatch('attemptGetFilteredUserContents', { userId: this.$store.getters.getUser.userId, filter: { status: 'in_progress' } })
    ]).then(([dataUser, dataUnreadMessages, dataOfferLetter, dataUserContents]) => {
      this.username = (dataUser && dataUser.name) || ''
      this.userUnreadMessages = dataUnreadMessages
      this.userOffer = dataOfferLetter
      this.handleContentInProgress = dataUserContents.data

      if (this.userUnreadMessages.pending_applications > 0 || this.userUnreadMessages.pending_meetings > 0 || this.userUnreadMessages.unread_messages > 0 || this.userUnreadMessages.inprogress_applications) {
        this.messagesCounter = this.userUnreadMessages.unread_messages

        this.$store.dispatch('attemptGetUserMatches').then(data => {
          this.acceptedRemoteInterviews = data.filter(match => { return match.position.meeting && match.position.meeting.accepted === true })
          this.pendingRemoteInterviews = data.filter(match => { return match.position.meeting && match.position.meeting.accepted === false })
          this.getMatches = data

          const allPositions = []
          for (const item of data) {
            allPositions.push(item.position)
          }

          this.getApplications = allPositions
          this.handleUnreadMessages = allPositions.filter(d => d.messagesCounter > 0).sort((a, b) => a.title.localeCompare(b.title))
        })
      }

      if (this.userUnreadMessages.pending_applications > 0) {
        this.$store.dispatch('attemptGetUserApplicationInvites').then(data => {
          this.userApplicationInvites = data
        })
      }
    })
    this.$store.commit('updateLastWorkspaceSelected', 'individual')
  },
  methods: {
    handleMatches () {
      this.$store.dispatch('attemptGetUserMatches')
    },
    handleMessages () {
      this.$store.dispatch('attemptGetUserMessages').then(dataUnreadMessages => {
        this.userUnreadMessages = dataUnreadMessages
      })
    },
    handleaUserApplicationInvites () {
      this.$store.dispatch('attemptGetUserApplicationInvites').then(data => {
        this.userApplicationInvites = data
      })
    },
    refreshPendingRemoteInterviews () {
      this.$store.dispatch('attemptGetUserMatches').then(data => {
        this.pendingRemoteInterviews = data.filter(match => {
          return match.position.meeting && match.position.meeting.accepted === false
        })
        this.acceptedRemoteInterviews = data.filter(match => {
          return match.position.meeting && match.position.meeting.accepted === true
        })
      })
    }
  }
}
</script>
<template>
  <div class="homepage-individual--container">
    <div class="default-home-title">
      <div>
        <h2>{{ $t('global:welcome') }}, {{ this.$store.getters.getUser.firstName }}! 👋</h2>
      </div>
      <p>{{ $t('home.greeting.description') }}</p>
    </div>

    <title-description-slot :title="$t('home.messages:not.read.yet')"
      :description="$t('home.messages:not.read.yet.description')" v-if="userUnreadMessages.unread_messages">

      <pending-message v-for="item in handleUnreadMessages" :key="item.id" :position="{ name: item.title, id: item.id }"
        @refreshUnreadMessages="handleMessages()" :company="{ name: item.company.name, image: item.company.logo }"
        :unreadAmount="item.messagesCounter.toString()" />
    </title-description-slot>

    <title-description-slot :title="$t('home.messages:offer.letter')"
      :description="$t('home.messages:offer.letter.description')" v-if="userOffer.length">
      <offer v-for="item in userOffer" :key="item.interview_id" :offer="item"/>
    </title-description-slot>

    <title-description-slot v-if="userApplicationInvites.length" :key="userApplicationInvites.length"
      :title="$t('home.messages:invite.for.position')"
      :description="$t('home.messages:invite.for.position.description')">
      <position-invite v-for="(invite) in userApplicationInvites" :key="invite.id" :inviteId="invite.id"
        @refreshUserApplicationInvites="handleaUserApplicationInvites()" :position="invite.position"
        :company="invite.position.company" />
    </title-description-slot>

    <title-description-card-anchor v-if="userUnreadMessages.inprogress_applications" icon="mdi-list-status"
      :title="$t('home.card.not.finished.candidacy.title')"
      :description="$t('home.card.not.finished.candidacy.description')" buttonTo="/my/applications"
      :buttonText="$t('home.access.not.finished.candidacy')"></title-description-card-anchor>

    <title-description-slot v-model="pendingRemoteInterviews" v-if="pendingRemoteInterviews.length"
      :title="$t('home.messages:invite.for.remote.interview')"
      :description="$t('home.messages:invite.for.remote.interview.description')">
      <interview-invite-card v-model="pendingRemoteInterviews" v-for="pendingInterview in pendingRemoteInterviews"
        @refreshPendingInterview="refreshPendingRemoteInterviews()" :key="pendingInterview.position.id"
        headIcon="mdi-email" :interviewId="pendingInterview.position.meeting.id"
        :headTitle="$t('interview.invite:invite.interview')" :inviteStatus="`${$t('global:pending')}!`"
        :positionName="pendingInterview.position.title" :company="pendingInterview.position.company"
        :interviewDate="{ day: formatDate(pendingInterview.position.meeting.date, 'full', $i18n.locale === 'pt-BR' ? 'pt-BR': 'en-US'), hour: getTime(pendingInterview.position.meeting.date) }"
        :confirmationModalTitle="$t('interview.invite:confirmation.modal.title')"
        :allowRejectMeeting="!pendingInterview.position.meeting.noReschedule"
        :confirmationModalDescription="$t('interview.invite:confirmation.modal.description')" />
    </title-description-slot>

    <title-description-slot v-if="acceptedRemoteInterviews.length" :title="$t('home.messages:booked.interviews')"
      :description="$t('home.messages:booked.interviews.description')">
      <interview-invite-card v-for="pendingInterview in acceptedRemoteInterviews" :key="pendingInterview.position.id"
        headIcon="mdi-video-account" :headTitle="$t('interview.invite:booked.interview')"
        :positionName="pendingInterview.position.title" :positionId="pendingInterview.position.id"
        :company="pendingInterview.position.company" :interviewId="pendingInterview.position.meeting.id"
        :interviewDate="{ day: formatDate(pendingInterview.position.meeting.date, 'full', $i18n.locale === 'pt-BR' ? 'pt-BR': 'en-US'), hour: getTime(pendingInterview.position.meeting.date) }"
        actionType="join" />
    </title-description-slot>

    <title-description-card-anchor v-if="handleContentInProgress && handleContentInProgress.length" icon="mdi-playlist-play"
      :title="$t('home.card.ongoing.courses.title')" :description="$t('home.card.ongoing.courses.description')"
      buttonTo="/my/contents/in-progress" :buttonText="$t('home.card.access.contents')"></title-description-card-anchor>

    <div class="skeleton-container" v-if="isPositionsLoading">
      <h5> {{ $t('home.positions.suggestions') }}</h5>
      <div class="skeleton-home">
        <v-skeleton-loader class="skeleton-card" type="card-avatar, article, actions" width="296px" height="380px"
          v-for="i in ['a', 'b', 'c']" :key="i" />
      </div>
    </div>

    <div class="skeleton-container" v-if="isCoursesLoading">
      <h5> {{ $t('home.positions.suggestions') }}</h5>
      <div class="skeleton-home">
        <v-skeleton-loader class="skeleton-card" type="card-avatar, article, actions" width="296px" height="380px"
          v-for="i in ['a', 'b', 'c']" :key="i" />
      </div>
    </div>

    <div class="skeleton-container" v-if="isDiscoveryLoading">
      <h5> {{ $t('individual.discovery.most.viewed:title') }}</h5>
      <div class="skeleton-home">
        <v-skeleton-loader class="skeleton-card" type="card-avatar, article, actions" width="296px" height="380px"
          v-for="i in ['a', 'b', 'c']" :key="i" />
      </div>
    </div>

    <div v-else>
      <GenericSlider v-if="positionSuggestions.length" title="home.positions.suggestions" :items="positionSuggestions" type="positions" />
      <GenericSlider v-if="courseSuggestions.length" title="home.courses.suggestions" :items="courseSuggestions" type="courses" />
      <GenericSlider v-if="discoveryList.length" title="individual.discovery.most.viewed:title" :items="discoveryList" type="contents" />
    </div>
    <router-view />
  </div>
</template>
<style lang="scss">
.homepage-individual--container {
  .skeleton-container {
    overflow: hidden;
    margin-top: 42px;
    h5 {
      font-size: 16px;
      line-height: 52px;
    }

    .skeleton-home {
      display: flex;
      width: 1200px;

      .skeleton-card {
        margin-right: 20px;
      }
    }
  }
}
</style>
